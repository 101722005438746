/* @import "~antd/dist/antd.css"; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html,
  body {
    -webkit-tap-highlight-color: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@layer components {
  .container {
    @apply w-11/12 lg:max-w-[80rem] mx-auto;
  }
}

html,
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-btn-primary {
  background: #1890ff !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background: #40a9ff !important;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead {
  background-color: rgb(44, 44, 44) !important;
  color: antiquewhite !important;
}

.ant-table-thead>tr>th {
  color: #efefef !important;
  font-weight: 700;
  background-color: transparent;
  font-size: 13px;
}

.ant-layout-sider {
  color: #ffffff;
}

.ant-menu-item a {
  color: #d8d8d8 !important;
  /* padding: 15px !important; */
  padding: 0 10px;
}

/* .ant-menu-item .active {
  background-color: #3a3a3a !important;
} */

.ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra>div {
  flex-wrap: wrap !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}